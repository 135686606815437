/*
 * IUSpinner = muestra el spinner
 */
import md5 from 'md5'

function IUSpinner() {
  function _crearSpinner(idoClas,nombre,numero) {
    var template = document.createElement("div")
    let tabla = document.getElementById(nombre)
    tabla.after(template)
    template.innerHTML = `
      <div class="contenedor_spinner${numero}" id="contenedor_spinner${numero}">
        <div class="spinner_mostrar">
          <div class="spinner-border text-success m-1" style="height: 100px; width: 100px;"></div>
        </div>
      </div>
      <style scoped>
      .contenedor_spinner${numero} {
        height:100%;
        width: 100%;
        position: absolute;
        top: 0px; 
        left: 0px;
        -webkit-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
        z-index: 10;
      }
      .spinner_mostrar {
        height: 100px;
        width: 100px;
        border-radius:100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
      </style>
    `
  }

  function _activarSpinner(numero){
    var spinner = document.getElementById(`contenedor_spinner${numero}`)

    if(spinner == null){
      return iu.msg.warning(`No se encontro la clase contenedor_spinner${numero}`)
    }

    spinner.style.visibility = 'visible'
    spinner.style.opacity = 1;
  }
  
  this.mostrar = function(identificador) {
    var identificar = identificador.charAt(0), numero = md5(identificador), tipo = ''

    if(identificar != '#') {
      return iu.msg.warning(`El identificador <strong style="color: red;">${identificador}</strong>`+
        ` no cuenta con el parametro <strong style="color: red;">"#"</strong>`+
        ` no se puede mostrar el spinner`)
    }

    if(identificar == '#') {
      var id = identificador.substring(1)
      tipo = document.getElementById(id)
      
      if(tipo == null){
        return
      }

      var montado= document.getElementsByClassName(`contenedor_spinner${numero}`)

      if (montado.length == 0) {
        _crearSpinner(identificar,id,numero)
        _activarSpinner(numero)
      } else {
        _activarSpinner(numero)
      }
    }
  }

  this.ocultar = function(identificador){
    var identificar = identificador.charAt(0), numero = md5(identificador)

    if(identificar != '#') {
      return iu.msg.warning(`El identificador <strong style="color: red;">${identificador}</strong>`+
        ` no cuenta con el parametro <strong style="color: red;">"#"</strong>`+
        ` no se puede ocultar el spinner`)
    }

    if(identificar == '#') {
      var spinner = document.getElementById(`contenedor_spinner${numero}`)
      
      if(spinner == null){
        return
      }
      spinner.style.visibility = 'hidden'
      spinner.style.opacity = 0;
    }
  }
}

export default IUSpinner
