import Login from '@/views/Login.vue'

export default [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/recuperar-cuenta',
    name: 'recuperarCuenta',
    component: () => import('@/views/RecuperarCuenta.vue')
  },
  {
    path: '/recuperar-identidades',
    name: 'recuperarIdentidades',
    component: () => import('@/views/RecuperarIdentidades.vue')
  },
  {
    path: '/modulos',
    name: 'modulos',
    component: () => import('@/views/modulos/Modulos.vue')
  },
  {
    path: '/modulos/selector',
    name: 'selectorModulo',
    component: () => import('@/views/modulos/Selector.vue')
  },
  {
    path: '/soporte-para-argusblack',
    name: 'soporteParaArgusBlack',
    component: () => import('@/views/tablero/SoporteParaArgusBlack.vue')
  },
  // Modulo ISP
  {
    path: '/inicio',
    name: 'inicio',
    component: () => import('@/views/tablero/Inicio.vue')
  },
  // Listado de clientes
  {
    path: '/clientes',
    name: 'listadoClientes',
    component: () => import('@/views/clientes/ListadoClientes.vue')
  },
  // Nuevo cliente
  {
    path: '/clientes/nuevo',
    name: 'nuevoCliente',
    component: () => import('@/views/clientes/EdicionCliente.vue')
  },
  // Edicion de cliente
  {
    path: '/clientes/:id_cliente/edicion',
    props: true,
    name: 'edicionCliente',
    component: () => import('@/views/clientes/EdicionCliente.vue')
  },
  // Tablero de cliente
  {
    path: '/clientes/:id_cliente/tablero/:nombrePanel?',
    props: true,
    name: 'tableroCliente',
    component: () => import('@/views/clientes/TableroCliente.vue')
  },
  // Contratos
  {
    path: '/clientes/:id_cliente/contratos/nuevo',
    props: true,
    name: 'nuevoContrato',
    component: () => import('@/views/contratos/EdicionContrato.vue')
  },
  {
    path: '/clientes/:id_cliente/contratos/:id_contrato/edicion',
    props: true,
    name: 'edicionContrato',
    component: () => import('@/views/contratos/EdicionContrato.vue')
  },
  // muestra los contratos cancelados
  {
    path: '/clientes/cancelacion-de-contratos',
    props: true,
    name: 'cancelacionContratos',
    component: () => import('@/views/contratos/CancelacionContratos.vue')
  },
  // Rutas del módulo de facturas
  {
    path: '/facturas',
    name: 'listadoFacturas',
    component: () => import('@/views/facturas/ListadoFacturas.vue')
  },
  {
    path: '/facturas/nueva/:id_cliente(\\d+)?',
    props: true,
    name: 'nuevaFactura',
    component: () => import('@/views/facturas/EdicionFactura/EdicionFactura.vue')
  },
  {
    path: '/facturas/:id_factura(\\d+)/edicion',
    props: true,
    name: 'edicionFactura',
    component: () => import('@/views/facturas/EdicionFactura/EdicionFactura.vue')
  },
  {
    path: '/facturas/:id(\\d+)/visor',
    props: true,
    name: 'factura',
    component: () => import('@/views/facturas/VistaFactura.vue')
  },
  {
    path: '/facturas/:id_factura(\\d+)/abonos',
    props: true,
    name: 'abonosFactura',
    component: () => import('@/views/facturas/AbonosFactura.vue')
  },
  /** Series de facturas */
  {
    path: '/facturas/series',
    name: 'listadoSeriesFacturas',
    component: () => import('@/views/facturas/ListadoSeriesFacturas.vue')
  },
  {
    path: '/facturas/series/nueva',
    name: 'nuevaSerieFacturas',
    component: () => import('@/views/facturas/SerieFacturas.vue')
  },
  {
    path: '/facturas/series/:id/edicion',
    props: true,
    name: 'edicionSerieFacturas',
    component: () => import('@/views/facturas/SerieFacturas.vue')
  },
  // Rutas del módulo de ventas
  {
    path: '/ventas',
    name: 'seleccionClienteVenta',
    component: () => import('@/views/ventas/SeleccionClienteVenta.vue')
  },
  {
    path: '/ventas/factura-rapida/:id_cliente(\\d+)?/nueva',
    props: true,
    name: 'nuevaFacturaRapida',
    component: () => import('@/views/facturas/EdicionFactura/EdicionFactura.vue')
  },
  {
    path: '/ventas/factura-rapida/:id(\\d+)?',
    props: true,
    name: 'facturaRapida',
    component: () => import('@/views/facturas/VistaFactura.vue')
  },
  // Rutas del módulo de pagos
  {
    path: '/pagos',
    name: 'pagos',
    component: () => import('@/views/pagos/InicioPagos.vue')
  },
  {
    path: '/pagos/resumen',
    name: 'resumenDia',
    component: () => import('@/views/pagos/ResumenDia.vue')
  },
  {
    path: '/pagos/:id_cliente/facturas-pendientes-pago',
    props: true,
    name: 'facturasPendientesPago',
    component: () => import('@/views/pagos/FacturasPendientesPago.vue')
  },
  {
    path: '/pagos/:id_cliente/confirmar-pago',
    props: true,
    name: 'confirmarPago',
    component: () => import('@/views/pagos/ConfirmarPago.vue')
  },  
  // Rutas de los bancos
  {
    path: '/bancos',
    name: 'bancos',
    component: () => import('@/views/bancos/ListadoBancos.vue')
  },
  {
    path: '/bancos/nuevo',
    name: 'nuevoBanco',
    component: () => import('@/views/bancos/EdicionBanco.vue')
  },
  {
    path: '/bancos/:id/edicion',
    props: true,
    name: 'edicionBanco',
    component: () => import('@/views/bancos/EdicionBanco.vue')
  },
  // Rutas para network
  {
    path: '/network/sitios',
    name: 'listadoSitios',
    component: () => import('@/views/network/sitios/ListadoSitios.vue')
  },
  {
    path: '/network/sitios/nuevo',
    name: 'nuevoSitio',
    component: () => import('@/views/network/sitios/EdicionSitio.vue')
  },
  {
    path: '/network/sitios/:id/edicion',
    props: true,
    name: 'edicionSitio',
    component: () => import('@/views/network/sitios/EdicionSitio.vue')
  },
  // Planes de servicios
  {
    path: '/network/planes',
    name: 'listadoPlanesServicio',
    component: () => import('@/views/network/planes/ListadoPlanesServicio.vue')
  },
  {
    path: '/network/planes/nuevo',
    name: 'nuevoPlanServicio',
    component: () => import('@/views/network/planes/EdicionPlanServicio.vue')
  },
  {
    path: '/network/planes/:id/edicion',
    props: true,
    name: 'edicionPlanServicio',
    component: () => import('@/views/network/planes/EdicionPlanServicio.vue')
  },
  // Perfiles de velocidad
  {
    path: '/network/planes/perfiles',
    name: 'listadoPerfilesVelocidad',
    component: () => import('@/views/network/planes/ListadoPerfilesVelocidad.vue')
  },
  {
    path: '/network/planes/perfiles/nuevo',
    name: 'nuevoPerfilVelocidad',
    component: () => import('@/views/network/planes/EdicionPerfilVelocidad.vue')
  },
  {
    path: '/network/planes/perfiles/:id/edicion',
    props: true,
    name: 'edicionPerfilVelocidad',
    component: () => import('@/views/network/planes/EdicionPerfilVelocidad.vue')
  },
  // Rutas para puntos de acceso
  {
    path: '/network/puntos-acceso',
    name: 'listadoAps',
    component: () => import('@/views/network/aps/ListadoAps.vue')
  },
  {
    path: '/network/puntos-acceso/nuevo',
    name: 'nuevoAp',
    component: () => import('@/views/network/aps/EdicionAp.vue')
  },
  {
    path: '/network/puntos-acceso/:id/edicion',
    props: true,
    name: 'edicionAp',
    component: () => import('@/views/network/aps/EdicionAp.vue')
  },
  {
    path: '/network/routers',
    name: 'listadoRouters',
    component: () => import('@/views/network/routers/ListadoRouters.vue')
  },
  {
    path: '/network/routers/nuevo',
    name: 'nuevoRouter',
    component: () => import('@/views/network/routers/EdicionRouter.vue')
  },
  {
    path: '/network/routers/:id/edicion',
    props: true,
    name: 'edicionRouter',
    component: () => import('@/views/network/routers/EdicionRouter.vue')
  },
  {
    path: "/network/routers/:id/tablero",
    props: true,
    name: "tableroRouter",
    component: () => import("@/views/network/routers/TableroRouter.vue")
  },
  {
    path: "/network/routers/:id/tablero-2",
    props: true,
    name: "tableroRouter2",
    component: () => import("@/views/network/routers/TableroRouter-respaldo.vue")
  },
  {
    path: '/network/routers/:id/registro-caidas',
    props: true,
    name: 'registroCaidasRouter',
    component: () => import('@/views/network/routers/RegistroCaidasRouter.vue')
  },
  {
    path: '/network/routers/:id/lista-proveedores-de-internet',
    name: 'listadoProveedoresInternetRouter',
    component: () => import('@/views/network/routers/ListadoProveedoresInternetRouter.vue')
  },
  {
    path: '/network/routers/:id/lista-proveedores-de-internet/nuevo',
    name: 'nuevoProveedorInternetRouter',
    component: () => import('@/views/network/routers/EdicionProveedorInternetRouter.vue')
  },
  {
    path: '/network/routers/:id/lista-proveedores-de-internet/:id_isp/edicion',
    name: 'edicionProveedorInternetRouter',
    component: () => import('@/views/network/routers/EdicionProveedorInternetRouter.vue')
  },
  {
    path: '/network/routers/:id/lista-proveedores-de-internet/:id_isp/lista-proveedores-de-internet',
    name: 'listadoProveedoresRespaldoInternetRouter',
    component: () => import('@/views/network/routers/ListadoProveedoresRespaldoInternetRouter.vue')
  },
  {
    path: '/network/routers/:id/lans',
    name: 'edicionLansRouter',
    component: () => import('@/views/network/routers/ListadoLansRouter.vue')
  },
  {
    path: '/network/routers/:id/lans/nueva',
    name: 'nuevaLanRouter',
    component: () => import('@/views/network/routers/EdicionLanRouter.vue')
  },
  {
    path: '/network/routers/:id/lans/:id_lan/edicion',
    props: true,
    name: 'edicionLanRouter',
    component: () => import('@/views/network/routers/EdicionLanRouter.vue')
  },
  {
    path: '/network/estdado-red',
    name: 'estadoRed',
    component: () => import('@/views/network/red/EstadoRed.vue')
  },
  // Rutas de Network - CPEs
  {
    path: '/network/cpes',
    name: 'listadoCpes',
    component: () => import('@/views/network/cpes/ListadoCpes.vue')
  },
  {
    path: '/network/cpes/nuevo',
    name: 'nuevoCpe',
    component: () => import('@/views/network/cpes/EdicionCpe.vue')
  },
  {
    path: '/network/cpes/:id/edicion',
    props: true,
    name: 'edicionCpe',
    component: () => import('@/views/network/cpes/EdicionCpe.vue')
  },
  {
    path: '/network/cpes/mapa',
    name: 'mapaCpes',
    component: () => import('@/views/network/mapa/MapaCpes.vue')
  },
  // Rutas de Network - Marcas de CPEs
  {
    path: '/network/cpes/marcas',
    name: 'listadoMarcasCpes',
    component: () => import('@/views/network/cpes/marcas/ListadoMarcasCpes.vue')
  },
  {
    path: '/network/cpes/marcas/nueva',
    name: 'nuevaMarcaCpes',
    component: () => import('@/views/network/cpes/marcas/EdicionMarcaCpes.vue')
  },
  {
    path: '/network/cpes/marcas/:id/edicion',
    props: true,
    name: 'edicionMarcaCpes',
    component: () => import('@/views/network/cpes/marcas/EdicionMarcaCpes.vue')
  },
  // Rutas de Network - Modelos de CPEs
  {
    path: '/network/cpes/modelos',
    name: 'listadoModelosCpes',
    component: () => import('@/views/network/cpes/modelos/ListadoModelosCpes.vue')
  },
  {
    path: '/network/cpes/modelos/nuevo',
    name: 'nuevoModeloCpes',
    component: () => import('@/views/network/cpes/modelos/EdicionModeloCpes.vue')
  },
  {
    path: '/network/cpes/modelos/:id/edicion',
    props: true,
    name: 'edicionModeloCpes',
    component: () => import('@/views/network/cpes/modelos/EdicionModeloCpes.vue')
  },
  //rutas de Network - Administracion de IPv4
  {
    path: '/network/cpes/ipv4',
    name: 'listadoIpv4',
    component: () => import('@/views/network/cpes/ipv4/ListadoIPv4.vue')
  },
  {
    path: '/network/cpes/ipv4/listado/wan',
    props: true,
    name: 'listadoIpWan',
    component: () => import('@/views/network/cpes/ipv4/ListadoIPWan.vue')
  },
  // Rutas de Network - Dispositivos de hogar
  {
    path: '/network/dhs',
    name: 'listadoDhs',
    component: () => import('@/views/network/dhs/ListadoDhs.vue')
  },
  {
    path: '/network/dhs/nuevo',
    name: 'nuevoDh',
    component: () => import('@/views/network/dhs/EdicionDh.vue')
  },
  {
    path: '/network/dhs/:id/edicion',
    props: true,
    name: 'edicionDh',
    component: () => import('@/views/network/dhs/EdicionDh.vue')
  },
  // Rutas de Network - Marcas de Dispositivos de hogar
  {
    path: '/network/dhs/marcas',
    name: 'listadoMarcasDhs',
    component: () => import('@/views/network/dhs/marcas/ListadoMarcasDhs.vue')
  },
  {
    path: '/network/dhs/marcas/nueva',
    name: 'nuevaMarcaDhs',
    component: () => import('@/views/network/dhs/marcas/EdicionMarcaDhs.vue')
  },
  {
    path: '/network/dhs/marcas/:id/edicion',
    props: true,
    name: 'edicionMarcaDhs',
    component: () => import('@/views/network/dhs/marcas/EdicionMarcaDhs.vue')
  },
  // Rutas de Network - Modelos de Dispositivos de hogar
  {
    path: '/network/dhs/modelos',
    name: 'listadoModelosDhs',
    component: () => import('@/views/network/dhs/modelos/ListadoModelosDhs.vue')
  },
  {
    path: '/network/dhs/modelos/nuevo',
    name: 'nuevoModeloDhs',
    component: () => import('@/views/network/dhs/modelos/EdicionModeloDhs.vue')
  },
  {
    path: '/network/dhs/modelos/:id/edicion',
    props: true,
    name: 'edicionModeloDhs',
    component: () => import('@/views/network/dhs/modelos/EdicionModeloDhs.vue')
  },


  // Rutas de Network - Tipos de Dispositivos de hogar
  {
    path: '/network/dhs/tipos',
    name: 'listadoTiposDhs',
    component: () => import('@/views/network/dhs/tipos/ListadoTiposDhs.vue')
  },
  {
    path: '/network/dhs/tipos/nuevo',
    name: 'nuevoTipoDhs',
    component: () => import('@/views/network/dhs/tipos/EdicionTipoDhs.vue')
  },
  {
    path: '/network/dhs/tipos/:id/edicion',
    props: true,
    name: 'edicionTipoDhs',
    component: () => import('@/views/network/dhs/tipos/EdicionTipoDhs.vue')
  },
  // Rutas del módulo de sesion
  {
    path: '/sesion/actual/perfil',
    name: 'perfilUsuarioSesion',
    component: () => import('@/views/sistema/usuarios/PerfilUsuarioSesion.vue')
  },
  // Rutas de Network - NOCs 
  {
    path: '/network/nocs',
    name: 'listadoNocs',
    component: () => import('@/views/network/nocs/ListadoNocs.vue')
  },
  {
    path: '/network/nocs/nuevo',
    name: 'nuevoNoc',
    component: () => import('@/views/network/nocs/EdicionNoc.vue')
  },
  {
    path: '/network/nocs/:id/edicion',
    props: true,
    name: 'edicionNoc',
    component: () => import('@/views/network/nocs/EdicionNoc.vue')
  },
  {
    path: '/network/nocs/:id/permisos-routers',
    props: true,
    name: 'permisosRouterNoc',
    component: () => import('@/views/network/nocs/PermisosRoutersNoc.vue')
  },
  // Rutas del módulo de sesion
  {
    path: '/sesion/actual/perfil',
    name: 'perfilUsuarioSesion',
    component: () => import('@/views/sistema/usuarios/PerfilUsuarioSesion.vue')
  },
  // Listado de productos
  {
    path: '/productos',
    name: 'listadoProductos',
    component: () => import('@/views/productos/ListadoProductos.vue')
  },
  {
    path: '/productos/nuevo',
    name: 'nuevoProducto',
    component: () => import('@/views/productos/EdicionProducto.vue')
  },
  {
    path: '/productos/:id/edicion',
    props: true,
    name: 'edicionProducto',
    component: () => import('@/views/productos/EdicionProducto.vue')
  },
  // Categorías de productos
  {
    path: '/productos/categorias',
    name: 'listadoCategoriasProductos',
    component: () => import('@/views/productos/categorias/ListadoCategoriasProductos.vue')
  },
  {
    path: '/productos/categorias/nueva',
    name: 'nuevaCategoriaProductos',
    component: () => import('@/views/productos/categorias/EdicionCategoriaProductos.vue')
  },
  {
    path: '/productos/categorias/:id/edicion',
    props: true,
    name: 'edicionCategoriaProductos',
    component: () => import('@/views/productos/categorias/EdicionCategoriaProductos.vue')
  },
  // Rutas del módulo de solicitudes de instalación
  // Listado de instalaciones
  {
    path: '/solicitudes-instalacion',
    name: 'listadoSolicitudesInstalacion',
    component: () => import('@/views/solicitudesInstalacion/ListadoSolicitudesInstalacion.vue')
  },
  // Nueva solicitud de instalación
  {
    path: '/solicitudes-instalacion/nueva',
    name: 'nuevaSolicitudInstalacion',
    component: () => import('@/views/solicitudesInstalacion/EdicionSolicitudInstalacion.vue')
  },
  // Edición de solicitud de instalación
  {
    path: '/solicitudes-instalacion/:id/edicion',
    name: 'edicionSolicitudInstalacion',
    props: true,
    component: () => import('@/views/solicitudesInstalacion/EdicionSolicitudInstalacion.vue')
  },
  // Edición de precio de la solicitud de instalación y los meses incluidos
  {
    path: '/solicitudes-instalacion/precio-y-meses',
    name: 'precioYMesesIncluidosParaSolicitudInstalacion',
    component: () => import('@/views/solicitudesInstalacion/PrecioYMesesIncluidos.vue')
  },
  // Rutas del módulo de soporte
  // Listado de tickets
  {
    path: '/soporte/tickets',
    name: 'listadoTicketsSoporte',
    component: () => import('@/views/soporte/ListadoTicketsSoporte.vue')
  },
  // Nuevo ticket de soporte
  {
    path: '/soporte/tickets/nuevo',
    name: 'nuevoTicketSoporte',
    component: () => import('@/views/soporte/EdicionTicketSoporte.vue')
  },
  // Edición de ticket de soporte
  {
    path: '/soporte/tickets/:id/edicion',
    props: true,
    name: 'edicionTicketSoporte',
    component: () => import('@/views/soporte/EdicionTicketSoporte.vue')
  },
  // Rutas de la seccion de sesiones de soporte
  {
    path: '/soporte/tickets/:id/sesiones-soporte',
    props: true,
    name: 'sesionesTicketSoporte',
    component: () => import('@/views/soporte/SesionesTicketSoporte.vue')
  },
  // Rutas de la seccion de calendario
  {
    path: '/calendario',
    name: 'calendario',
    component: () => import('@/views/calendario/Calendario.vue')
  },
  {
    path: '/calendario/ajustes',
    name: 'ajustesCalendario',
    component: () => import('@/views/calendario/AjustesCalendario.vue')
  },
  ,
  {
    path: '/calendario/ajustes/nuevo',
    name: 'nuevoAjusteCalendario',
    component: () => import('@/views/calendario/EdicionAjusteCalendario.vue')
  },
  {
    path: '/calendario/ajustes/:id/edicion',
    name: 'edicionAjusteCalendario',
    component: () => import('@/views/calendario/EdicionAjusteCalendario.vue')
  },
  {
    path: '/calendario/ajustes/:id/visor',
    name: 'visorAjusteCalendario',
    component: () => import('@/views/calendario/EdicionAjusteCalendario.vue')
  },
  {
    path: '/calendario/eventos',
    name: 'eventosCalendario',
    component: () => import('@/views/calendario/eventos/EventosCalendario.vue')
  },
  // Listado de reporte de cloentes por sitio
  {
    path: '/reportes',
    redirect: { name: 'listadoReporteClientesSitio'}
  },
  {
    path: '/reportes/clientes-por-sitio',
    name: 'listadoReporteClientesSitio',
    component: () => import('@/views/reportes/reporteClienteSitio/ListadoClienteSitio.vue')
  },
  /** Reportes de facturas */
  {
    path: '/reportes/facturas',
    name: 'reportesFacturas',
    component: () => import('@/views/reportes/facturas/ReportesFacturas.vue')
  },
  // Rutas del Sistema - Ajustes
  //Ajustes de sistema
  {
    path: '/sistema/ajustes',
    name: 'ajustesSistema',
    //component: () => import('@/views/sistema/ajustesSistema/AjustesSistema.vue')
  },
  //Acerca de ArgusBlack
  {
    path: '/sistema/acerca-de',
    name: 'acercaDe',
    component: () => import('@/views/sistema/acercaDe/AcercaDe.vue')
  },
  //Registro de actualizaciones de ArgusBlack
  {
    path: '/sistema/acerca-de/registro-de-actualizaciones',
    name: 'registroActualizacion',
    component: () => import('@/views/sistema/acercaDe/RegistroActualizaciones.vue')
  },
  // Certificado de licencia de software
  {
    path: '/sistema/certificado-licencia',
    name: 'certificadoLicencia',
    component: () => import('@/views/sistema/acercaDe/CertificadoLicencia.vue')
  },
  // Sistema Cuenta
  {
    path: '/sistema/cuenta',
    name: 'sistemaCuenta',
    component: () => import('@/views/sistema/cuenta/Tablero.vue')
  },
  {
    path: "/sistema/cuenta/planes-isp",
    name: "sistemaPlanesIsp",
    component: () => import("@/views/sistema/cuenta/isp/PlanesIsp.vue")
  },
  {
    path: "/sistema/cuenta/planes-isp/:id_plan/actualizar-plan-isp",
    props: true,
    name: "actualizarPlanIsp",
    component: () => import("@/views/sistema/cuenta/isp/ActualizarPlanIsp.vue")
  },
  {
    path: "/sistema/cuenta/modulo-hotspot",
    name: "sistemaModuloHotspot",
    component: () => import("@/views/sistema/cuenta/hotspot/ModulosHotspot.vue")
  },
  {
    path: "/sistema/cuenta/modulo-hotspot/:id_plan/actualizar-plan-hotspot",
    props: true,
    name: "actualizarPlanHotspot",
    component: () => import("@/views/sistema/cuenta/hotspot/ActualizarPlanHotspot.vue")
  },
  {
    path: "/sistema/cuenta/modulo-inventario",
    name: "sistemaModuloInventario",
    component: () => import("@/views/sistema/cuenta/inventario/ModulosInventario.vue")
  },
  {
    path: "/sistema/cuenta/modulo-inventario/:id_plan/actualizar-plan-inventario",
    props: true,
    name: "actualizarPlanInventario",
    component: () => import("@/views/sistema/cuenta/inventario/ActualizarPlanInventario.vue")
  },
  {
    path: "/sistema/cuenta/modulo-vpn",
    name: "sistemaModuloVpn",
    component: () => import("@/views/sistema/cuenta/vpn/GestionVpn.vue")
  },
  {
    path: "/sistema/cuenta/modulo-vpn/:id_plan/actualizar-plan-vpn",
    props: true,
    name: "actualizarPlanVpn",
    component: () => import("@/views/sistema/cuenta/vpn/ActualizarPlanVpn.vue")
  },
  {
    path: "/sistema/cuenta/modulo-soporte",
    name: "sistemaSoporte",
    component: () => import("@/views/sistema/cuenta/soporte/ModulosSoportes.vue")
  },

  {
    path: "/sistema/cuenta/aprobado",
    name: "aprobado",
    component: () => import("@/views/sistema/cuenta/pasarela/Aprobado.vue")
  },
  {
    path: "/sistema/cuenta/pendiente",
    name: "pendiente",
    component: () => import("@/views/sistema/cuenta/pasarela/Pendiente.vue")
  },
  {
    path: "/sistema/cuenta/fallido",
    name: "fallido",
    component: () => import("@/views/sistema/cuenta/pasarela/Fallido.vue")
  },
  // Rutas para administración de ajustes del sistema
  // General
  {
    path: '/sistema/ajustes/general',
    name: 'ajustesGeneralesSistema',
    component: () => import('@/views/sistema/ajustesSistema/General.vue')
  },
  // Clientes
  {
    path: '/sistema/ajustes/clientes',
    name: 'ajustesClientesSistema',
    component: () => import ('@/views/sistema/ajustesSistema/Clientes.vue')
  },
  // Facturación
  {
    path: '/sistema/ajustes/facturacion/general',
    name: 'ajustesFacturacionSistema',
    component: () => import ('@/views/sistema/ajustesSistema/facturacion/Facturacion.vue')
  },
  // Plantilla de factura
  {
    path: '/sistema/ajustes/facturacion/plantilla',
    name: 'plantillaFacturaSistema',
    component: () => import('@/views/sistema/ajustesSistema/facturacion/PlantillaFactura/PlantillaFactura.vue')
  },
  // Inventario
  {
    path: '/sistema/ajustes/inventario',
    name: 'ajustesInventarioSistema',
    component: () => import ('@/views/sistema/ajustesSistema/Inventario.vue')
  },
  // Ticket de pago
  {
    path: '/sistema/ajustes/ticket-pago',
    name: 'ajustesTicketPagoSistema',
    component: () => import ('@/views/sistema/ajustesSistema/TicketPago.vue')
  },
  // Plantilla de notificación por correo
  {
    path: '/sistema/ajustes/plantilla-notificacion-por-correo',
    name: 'ajustesPlantillaNotificacionPorCorreoSistema',
    component: () => import ('@/views/sistema/ajustesSistema/PlantillaNotificacionCorreo.vue')
  },
  // Plantilla de servicio suspendido
  {
    path: '/sistema/ajustes/plantilla-servicio-suspendido',
    name: 'ajustesPlantillaServicioSuspendidoSistema',
    component: () => import ('@/views/sistema/ajustesSistema/PlantillaServicioSuspendido.vue')
  },
  // Network
  {
    path: '/sistema/ajustes/network',
    name: 'ajustesNetwork',
    component: () => import ('@/views/sistema/ajustesSistema/Network.vue')
  },
  // Database
  {
    path: '/sistema/ajustes/database',
    name: 'ajustesDatabase',
    component: () => import ('@/views/sistema/ajustesSistema/Database.vue')
  },

  // Pasarelas de pago
  {
    path: '/sistema/ajustes/pasarelas-pago',
    name: 'ajustesPasarelasPago',
    component: () => import ('@/views/sistema/ajustesSistema/pasarelasPago/PasarelasPago.vue')
  },
  {
    path: '/sistema/ajustes/pasarelas-pago/:id/edicion',
    name: 'edicionPasarelasPago',
    component: () => import ('@/views/sistema/ajustesSistema/pasarelasPago/EdicionPasarela.vue')
  },

  // Rutas para administración de ajustes del sistema
  // General
  {
    path: '/sistema/mi-servicio-de-internet/ajustes/general',
    name: 'ajustesGeneralesSistemaMSI',
    component: () => import('@/views/sistema/ajustesSistema/GeneralMSI.vue')
  }, 
  // Pasarelas de pago
  {
    path: '/sistema/mi-servicio-de-internet/ajustes/pasarelas-pago',
    name: 'ajustesPasarelasPagoSistema',
    component: () => import ('@/views/sistema/ajustesSistema/pasarelasPago/PasarelasPago.vue')
  },
  // Medios de pago
  {
    path: '/sistema/mi-servicio-de-internet/ajustes/medios-pago-panel-cliente',
    name: 'ajustesMediosPagoPanelClienteSistema',
    component: () => import ('@/views/sistema/ajustesSistema/MediosPagoPanelCliente.vue')
  },
  // Rutas del Sistema - Usuarios
  // Listado de usuarios
  {
    path: '/sistema/usuarios',
    name: 'listadoUsuarios',
    component: () => import('@/views/sistema/usuarios/ListadoUsuarios.vue')
  },
  // Nuevo usuario
  {
    path: '/sistema/usuarios/nuevo',
    name: 'nuevoUsuario',
    component: () => import('@/views/sistema/usuarios/EdicionUsuario.vue')
  },
  // Edición usuario
  {
    path: '/sistema/usuarios/:idUsuario/edicion',
    props: true,
    name: 'edicionUsuario',
    component: () => import('@/views/sistema/usuarios/EdicionUsuario.vue')
  },

  // Rutas del Sistema - Aplicaciones
  // Listado de aplicaciones
  {
    path: '/sistema/usuarios/aplicaciones',
    name: 'listadoAplicaciones',
    component: () => import('@/views/sistema/aplicaciones/ListadoAplicaciones.vue')
  },
  // Nueva aplicacion
  {
    path: '/sistema/usuarios/aplicaciones/nueva',
    name: 'nuevaAplicacion',
    component: () => import('@/views/sistema/aplicaciones/EdicionAplicacion.vue')
  },
  // Edición aplicacion
  {
    path: '/sistema/usuarios/aplicaciones/:id/edicion',
    props: true,
    name: 'edicionAplicacion',
    component: () => import('@/views/sistema/aplicaciones/EdicionAplicacion.vue')
  },

  // Rutas del Sistema - Roles de usuarios
  //Roles de usuarios de sistema
  {
    path: '/sistema/usuarios/roles',
    name: 'listadoRolesUsuarios',
    component: () => import('@/views/sistema/roles/ListadoRolesUsuarios.vue')
  },
  //Nuevo Rol Usuario
  {
    path: '/sistema/usuarios/roles/nuevo',
    name: 'nuevoRolUsuarios',
    component: () => import('@/views/sistema/roles/EdicionRolUsuario.vue')
  },
  //Edicion rol usuario
  {
    path: '/sistema/usuarios/roles/:id/edicion',
    props: true,
    name: 'edicionRolUsuarios',
    component: () => import('@/views/sistema/roles/EdicionRolUsuario.vue')
  },
  // Permisos de roles
  // Bloques de funciones del sistema
  {
    path: '/sistema/permisos/bloques',
    name: 'bloquesFunciones',
    component: () => import('@/views/sistema/bloques/BloquesFunciones.vue')
  },
  // Control de permisos del rol de usuarios
  {
    path: '/sistema/permisos/:id_rol/edicion',
    name: 'edicionPermisosRol',
    component: () => import('@/views/sistema/bloques/Permisos.vue')
  },
  {
    path: '/sistema/usuarios/roles/notificaciones',
    props:true,
    name: 'rolNotificaciones',
    component: () => import('@/views/sistema/notificaciones/Notificaciones.vue')
  },
  // Modulo Iventario
  {
    path: '/inventario/inicio',
    name: 'tableroInventario',
    component: () => import('@/views/inventario/tablero/Inicio.vue')
  },
  //Listado de familias de articulos
  {
    path: '/inventario/familias-de-articulos',
    name: 'listadoFamiliaArticulos',
    component: () =>import('@/views/inventario/familiaArticulos/ListadoFamiliaArticulos.vue')
  },
  //Nueva familia de articulos
  {
    path: '/inventario/familias-de-articulos/nueva',
    name: 'nuevaFamiliaArticulos',
    component: () =>
      import('@/views/inventario/familiaArticulos/EdicionFamiliaArticulo.vue')
  },
  //edicion de la familia de articulos
  {
    path: '/familias-de-articulos/:id/edicion',
    name: 'edicionFamiliaArticulos',
    component: () =>
      import('@/views/inventario/familiaArticulos/EdicionFamiliaArticulo.vue')
  },
  {
    path: '/inventario/articulos',
    name: 'listadoArticulos',
    component: () => import('@/views/inventario/articulos/ListadoArticulos.vue')
  },
  {
    path: '/inventario/articulos/nuevo',
    name: 'nuevoArticulo',
    component: () => import('@/views/inventario/articulos/EdicionArticulo.vue')
  },
  {
    path: '/inventario/articulos/:id/edicion',
    name: 'edicionArticulo',
    component: () => import('@/views/inventario/articulos/EdicionArticulo.vue')
  },
  {
    path: '/inventario/entradas',
    name: 'listadoEntradasAlmacen',
    component: () => import('@/views/inventario/entradas/ListadoEntradasAlmacen.vue')
  },
  {
    path: '/inventario/entradas/nueva',
    name: 'nuevaEntradaAlmacen',
    component: () => import('@/views/inventario/entradas/EntradaAlmacen.vue')
  },
  {
    path: '/inventario/entradas/:id/visor',
    name: 'visorEntradaAlmacen',
    component: () => import('@/views/inventario/entradas/EntradaAlmacen.vue')
  },
  {
    path: '/inventario/salidas',
    name: 'listadoSalidasAlmacen',
    component: () => import('@/views/inventario/salidas/ListadoSalidasAlmacen.vue')
  },
  {
    path: '/inventario/salidas/nueva',
    name: 'nuevaSalidaAlmacen',
    component: () => import('@/views/inventario/salidas/SalidaAlmacen.vue')
  },
  {
    path: '/inventario/salidas/:id/visor',
    name: 'visorSalidaAlmacen',
    component: () => import('@/views/inventario/salidas/SalidaAlmacen.vue')
  },
  {
    path: '/inventario/conversiones',
    name: 'listadoConversionesArticulos',
    component: () => import('@/views/inventario/conversiones/ListadoConversionesArticulos.vue')
  },
  {
    path: '/inventario/conversiones/nueva',
    name: 'nuevaConversionArticulos',
    component: () => import('@/views/inventario/conversiones/ConversionArticulos.vue')
  },
  {
    path: '/inventario/conversiones/:id/visor',
    name: 'visorConversionArticulos',
    component: () => import('@/views/inventario/conversiones/ConversionArticulos.vue')
  },
  {
    path: '/inventario/movimientos',
    name: 'listadoMovimientosArticulos',
    component: () => import('@/views/inventario/movimientos/ListadoMovimientos.vue')
  },
  {
    path: '/inventario/movimientos/:id/detalles',
    name: 'detallesMovimiento',
    component: () => import('@/views/inventario/movimientos/DetallesMovimiento.vue')
  },
  {
    path: '/inventario/solicitudes',
    name: 'listadoSolicitudes',
    component: () => import('@/views/inventario/solicitudes/ListadoSolicitudesArticulos.vue')
  },
  {
    path: '/inventario/solicitudes/nueva',
    name: 'nuevaSolicitud',
    component: () => import('@/views/inventario/solicitudes/EdicionSolicitudArticulo.vue')
  },
  {
    path: '/inventario/solicitudes/:id/visor',
    name: 'visorSolicitud',
    component: () => import('@/views/inventario/solicitudes/EdicionSolicitudArticulo.vue')
  },
  // Rutas de compras
  {
    path: '/compras',
    name: 'tableroCompras',
    component: () => import('@/views/compras/tablero/inicio.vue')
  },
  {
    path: '/compras/configuracion',
    name: 'configuracionCompras',
    component: () => import('@/views/compras/ConfiguracionCompras.vue')
  },
  // Rutas de compras/requerimientos
  {
    path: '/compras/requerimientos',
    name: 'listadoRequerimientos',
    component: () => import('@/views/compras/requerimientos/ListadoRequerimientos.vue')
  },
  {
    path: '/compras/requerimientos/nuevo',
    name: 'nuevoRequerimiento',
    component: () => import('@/views/compras/requerimientos/EdicionRequerimiento.vue')
  },
  {
    path: '/compras/requerimientos/:id/edicion',
    name: 'edicionRequerimiento',
    component: () => import('@/views/compras/requerimientos/EdicionRequerimiento.vue')
  },
  {
    path: '/compras/requerimientos/:id/visor',
    name: 'visorRequerimiento',
    component: () => import('@/views/compras/requerimientos/EdicionRequerimiento.vue')
  },
  // Rutas de compras/cotizaziones
  {
    path: '/compras/cotizaciones',
    name: 'listadoCotizaciones',
    component: () => import('@/views/compras/cotizaciones/listadoCotizaciones.vue')
  },
  {
    path: '/compras/cotizaciones/nuevo',
    name: 'nuevoCotizacion',
    component: () => import('@/views/compras/cotizaciones/EdicionCotizacion.vue')
  },
  {
    path: '/compras/cotizaciones/:id/edicion',
    name: 'edicionCotizacion',
    component: () => import('@/views/compras/cotizaciones/EdicionCotizacion.vue')
  },
  {
    path: '/compras/cotizaciones/:id/visor',
    name: 'visorCotizacion',
    component: () => import('@/views/compras/cotizaciones/EdicionCotizacion.vue')
  },
  // Rutas de compras/ordenes-de-compras
  {
    path: '/compras/ordenes-de-compra',
    name: 'listadoOrdenesCompra',
    component: () => import('@/views/compras/ordenesCompra/listadoOrdenesCompra.vue')
  },
  {
    path: '/compras/ordenes-de-compra/nuevo',
    name: 'nuevoOrdenCompra',
    component: () => import('@/views/compras/ordenesCompra/EdicionOrdenCompra.vue')
  },
  {
    path: '/compras/ordenes-de-compra/:id/edicion',
    name: 'edicionOrdenCompra',
    component: () => import('@/views/compras/ordenesCompra/EdicionOrdenCompra.vue')
  },
  {
    path: '/compras/ordenes-de-compra/:id/visor',
    name: 'visorOrdenCompra',
    component: () => import('@/views/compras/ordenesCompra/EdicionOrdenCompra.vue')
  },
  // Rutas de compras/proveedores
  {
    path: '/compras/proveedores',
    name: 'listadoProveedores',
    component: () => import('@/views/compras/proveedores/listadoProveedores.vue')
  },
  {
    path: '/compras/proveedores/nuevo',
    name: 'nuevoProveedor',
    component: () => import('@/views/compras/proveedores/edicionProveedor.vue')
  },
  {
    path: '/compras/proveedores/:id/edicion',
    name: 'edicionProveedor',
    component: () => import('@/views/compras/proveedores/edicionProveedor.vue')
  },
  // Modulo Hotspot
  // Rutas del módulo de hotspots/tablero
  {
    path: '/hotspots/tablero',
    name: 'tableroHotspot',
    component: () => import('@/views/hotspot/tablero/TableroHotspot.vue')
  },
  // Rutas del módulo de hotspots/routers
  // routers de hotspot
  {
    path: '/hotspots/hotspots',
    name: 'hotspots',
    component: () => import('@/views/hotspot/hotspots/ListadoHotspots.vue')
  },
  {
    path: '/hotspots/hotspots/nuevo',
    name: 'nuevoHotspot',
    component: () => import('@/views/hotspot/hotspots/EdicionHotspot.vue')
  },
  {
    path: '/hotspots/hotspots/:id/edicion',
    name: 'edicionHotspot',
    component: () => import('@/views/hotspot/hotspots/EdicionHotspot.vue')
  },
  // Rutas del módulo de hotspots/perfiles
  // usuarios de hotspot
  {
    path: '/hotspots/perfiles',
    name: 'perfilesHotspot',
    component: () => import('@/views/hotspot/perfiles/ListadoPerfiles.vue')
  },
  {
    path: '/hotspots/perfiles/nuevo',
    name: 'nuevoPerfilHotspot',
    component: () => import('@/views/hotspot/perfiles/EdicionPerfil.vue')
  },
  {
    path: '/hotspots/perfiles/:id/edicion',
    name: 'edicionPerfilHotspot',
    component: () => import('@/views/hotspot/perfiles/EdicionPerfil.vue')
  },
  // Rutas del módulo de hotspots/clientes
  // {
  //   path: '/hotspots/clientes',
  //   name: 'clientesHotspot',
  //   component: () => import('@/views/hotspot/clientes/ListadoClientes.vue')
  // },
  // {
  //   path: '/hotspots/clientes/nuevo',
  //   name: 'nuevoClienteHotspot',
  //   component: () => import('@/views/hotspot/clientes/EdicionCliente.vue')
  // },
  // {
  //   path: '/hotspots/clientes/:id/edicion',
  //   name: 'edicionClienteHotspot',
  //   component: () => import('@/views/hotspot/clientes/EdicionCliente.vue')
  // },
  // Rutas del módulo de hotspots/fichas
  // fichas de hotspot
  {
    path: '/hotspots/fichas',
    name: 'fichasHotspot',
    component: () => import('@/views/hotspot/fichas/ListadoFichas.vue')
  },
  {
    path: '/hotspots/fichas/generar',
    name: 'generarFichasHotspot',
    component: () => import('@/views/hotspot/fichas/GenerarFichas.vue')
  },
  {
    path:'/hotspots/fichas/:id/tipos',
    name:'fichasPorTiposHotspot',
    component: () => import('@/views/hotspot/fichas/ListadoFichasPorTiposOVendedores.vue')
  },
  {
    path:'/hotspots/fichas/:id/vendedores',
    name:'fichasPorVendedoresHotspot',
    component: () => import('@/views/hotspot/fichas/ListadoFichasPorTiposOVendedores.vue')
  },
  //ruta para imprimir fichas seleccionadas
  {
    path: '/hotspots/fichas/imprimir',
    name: 'imprimirFichasHotspot',
    component: () => import('@/views/hotspot/fichas/ImprimirFichas.vue')
  },
  // Rutas del módulo de hotspots/fichas/tipos
  // fichas tipo de hotspot
  {
    path: '/hotspots/fichas/tipos',
    name: 'fichasTiposHotspot',
    component: () => import('@/views/hotspot/fichas/tipoFicha/Listado.vue')
  },
  {
    path: '/hotspots/fichas/tipos/nuevo',
    name: 'nuevoFichaTipoHotspot',
    component: () => import('@/views/hotspot/fichas/tipoFicha/EdicionFichaTipo.vue')
  },
  {
    path: '/hotspots/fichas/tipos/:id/edicion',
    name: 'edicionFichaTipoHotspot',
    component: () => import('@/views/hotspot/fichas/tipoFicha/EdicionFichaTipo.vue')
  },
  // Rutas del módulo de hotspots/puntos-de-venta
  // vendedores de hotspot
  // {
  //   path: '/hotspots/puntos-de-venta',
  //   name: 'puntoVentaHotspot',
  //   component: () => import('@/views/hotspot/puntoVenta/ListadoVenta.vue')
  // },
  // {
  //   path: '/hotspots/puntos-de-venta/nuevo',
  //   name: 'nuevoPuntoVentaHotspot',
  //   component: () => import('@/views/hotspot/puntoVenta/EdicionVenta.vue')
  // },
  // {
  //   path: '/hotspots/puntos-de-venta/:id/edicion',
  //   props: true,
  //   name: 'edicionPuntoVentaHotspot',
  //   component: () => import('@/views/hotspot/puntoVenta/EdicionVenta.vue')
  // },
  // Rutas del módulo de hotspots/vendedores
  {
    path: '/hotspots/vendedores',
    name: 'vendedoresHotspot',
    component: () => import('@/views/hotspot/vendedores/ListadoVendedores.vue')
  },
  {
    path: '/hotspots/vendedores/nuevo',
    name: 'nuevoVendedorHotspot',
    component: () => import('@/views/hotspot/vendedores/EdicionVendedor.vue')
  },
  {
    path: '/hotspots/vendedores/:id/edicion',
    props: true,
    name: 'edicionVendedorHotspot',
    component: () => import('@/views/hotspot/vendedores/EdicionVendedor.vue')
  },
  // Rutas del módulo de hotspots/portal-cautivo
  // layout de hotspot
  {
    path: '/hotspots/layouts/portal-cautivo',
    name: 'portalCautivoHotspot',
    component: () => import('@/views/hotspot/layouts/portalCautivo/listadoPortalCautivoHotspot.vue')
  },
  {
    path: '/hotspots/layouts/portal-cautivo/nuevo',
    name: 'nuevoPortalCautivoHotspot',
    component: () => import('@/views/hotspot/layouts/portalCautivo/EdicionPortalCautivoHotspot.vue')
  },
  {
    path: '/hotspots/layouts/portal-cautivo/:id/edicion',
    name: 'edicionPortalCautivoHotspot',
    component: () => import('@/views/hotspot/layouts/portalCautivo/EdicionPortalCautivoHotspot.vue')
  },
  // Rutas del módulo de hotspots/portal-cautivo
  // layout de hotspot
  {
    path: '/hotspots/layouts/fichas',
    name: 'fichasLayoutHotspot',
    component: () => import('@/views/hotspot/layouts/fichas/ListadoLayoutFichasHotspot.vue')
  },
  {
    path: '/hotspots/layouts/fichas/nuevo',
    name: 'nuevoFichasLayoutHotspot',
    component: () => import('@/views/hotspot/layouts/fichas/EdicionLayoutFichaHotspot.vue')
  },
  {
    path: '/hotspots/layouts/fichas/:id/edicion',
    name: 'edicionFichasLayoutHotspot',
    component: () => import('@/views/hotspot/layouts/fichas/EdicionLayoutFichaHotspot.vue')
  },  
  // Rutas del módulo de hotspots/corte-de-caja
  // {
  //   path: '/hotspots/cortes-de-caja',
  //   name: 'cortesCajaHotspot',
  //   component: () => import('@/views/hotspot/cortesCaja/ListadoCortesCaja.vue')
  // },
  // Rutas del módulo de hotspots/generar-y-vender
  {
    path: '/hotspots/generar-y-vender',
    name: 'generarVenderHotspot',
    component: () => import('@/views/hotspot/generarVender/GenerarVender.vue')
  },
  // Notificaciones
  {
    path: '/notificaciones',
    name: 'listadoNotificaciones',
    component: () => import('@/views/notificaciones/ListadoNotificaciones.vue')
  },
  {
    path: '/notificaciones/:id/visor',
    name: 'visorNotificacion',
    component: () => import('@/views/notificaciones/VisorMensaje.vue')
  },
  {
    path: '/notificaciones/redactar',
    name: "redactarNotificacion",
    component: () => import('@/views/notificaciones/RedactarMensaje.vue')
  },
  // fin de notificaciones
  // Modulo VPN
  {
    path: '/vpn/inicio',
    name: 'tableroVpn',
    component: () => import('@/views/vpn/Inicio.vue')
  },
  {
    path: '/vpn/credenciales',
    name: 'listadoCredencialesVpn',
    component: () => import('@/views/vpn/ListadoCredenciales.vue')
  },
  {
    path: '/vpn/credenciales/nuevo',
    name: 'nuevaCredencialVpn',
    component: () => import('@/views/vpn/EdicionCredencial.vue')
  },
  {
    path: '/vpn/credenciales/:id/edicion',
    name: 'edicionCredencialVpn',
    component: () => import('@/views/vpn/EdicionCredencial.vue')
  },
  // Fin del modulo VPN
  {
    path: '/prueba',
    name: 'prueba',
    component: () => import('@/views/Prueba.vue')
  },
  // Ruta por default que redirige al selectorModulo, en caso de que
  {
    path: '/:pathMatch(.*)*',
    name: 'default',
    redirect: { name: 'selectorModulo' }
  }
]
